import {
  EuiFlexItem,
  EuiFlexGroup,
  EuiText,
  EuiSpacer,
  EuiIcon,
} from "@elastic/eui";
import "./web-item-detail-ordering.scss";
import { WebItemDetailOrderingProps } from "../../../common/web/web-item-detail-ordering";
import {
  WalgreensDeliveryIcon,
  WalgreensPickupIcon,
} from "../../../../assets/assets";
import { Location } from "../../../../services/geoapify-service";
import { useSelector } from "react-redux";
import { locationStateSelector } from "../../../../state/slices/location-slice";

export const WalgreensWebItemDetailOrdering = ({
  item,
  itemCount,
  updateItemCount,
  increment,
  decrement,
  addToCart,
}: WebItemDetailOrderingProps) => {
  const location: Location | undefined = useSelector(locationStateSelector);

  return (
    <EuiFlexItem className="walgreens-item-ordering-wrapper">
      <EuiFlexGroup
        className="pickup"
        gutterSize="s"
        justifyContent="flexStart"
        alignItems="center"
        style={{ flexGrow: 0 }}
      >
        <EuiFlexGroup gutterSize="none" style={{ flexGrow: 0 }}>
          <span className="radio"> </span>
          <span className="radio-fill"> </span>
        </EuiFlexGroup>
        <img src={WalgreensPickupIcon} alt="pickup" />
        <EuiText className="title" size="m">
          Store Pickup
        </EuiText>
      </EuiFlexGroup>
      <EuiFlexGroup
        className="pickup-details"
        direction="column"
        justifyContent="flexStart"
        gutterSize="s"
        alignItems="baseline"
      >
        <span style={{ color: "#00853e", fontWeight: "700", fontSize: "14px" }}>
          In stock at{" "}
        </span>
        2424 N MAIN ST.{" "}
        <span className="bold-and-underline">
          {location?.city?.toUpperCase()}, {location?.stateCode}{" "}
          {location?.postCode}
        </span>
        <EuiSpacer size="s" />
        <span
          style={{ fontSize: "14px", textAlign: "left", lineHeight: "22px" }}
        >
          Ready in as little as{" "}
          <span style={{ textDecoration: "underline", fontWeight: "700" }}>
            30 minutes
          </span>{" "}
          if order placed by 9pm. Curbside or drive-thru pickup available. $10
          minimum order required.{" "}
          <span style={{ textDecoration: "underline", fontWeight: "700" }}>
            Details
          </span>
        </span>
        <EuiFlexGroup className="button">
          <EuiFlexItem className="count" grow={false}>
            1 <EuiIcon type={"arrowDown"} />
          </EuiFlexItem>
          <EuiFlexItem className="action" onClick={addToCart}>
            Add for pickup
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexGroup>

      <EuiFlexGroup
        className="delivery"
        gutterSize="none"
        justifyContent="flexStart"
        alignItems="flexStart"
        style={{ flexGrow: 0 }}
        direction="column"
      >
        <EuiFlexGroup gutterSize="s">
          <EuiFlexGroup gutterSize="none" style={{ flexGrow: 0 }}>
            <span className="radio"> </span>
          </EuiFlexGroup>
          <img src={WalgreensDeliveryIcon} alt="pickup" />
          <EuiText className="title" size="m">
            Same Day Delivery
          </EuiText>
        </EuiFlexGroup>
        <EuiText className="details">As soon as 1 hour or schedule</EuiText>
        <EuiText className="details">delivery.</EuiText>
      </EuiFlexGroup>

      <EuiFlexGroup
        className="shipping"
        gutterSize="none"
        justifyContent="flexStart"
        alignItems="flexStart"
        style={{ flexGrow: 0 }}
        direction="column"
      >
        <EuiFlexGroup gutterSize="s">
          <EuiFlexGroup gutterSize="none" style={{ flexGrow: 0 }}>
            <span className="radio"> </span>
          </EuiFlexGroup>
          <img src={WalgreensPickupIcon} alt="pickup" />

          <EuiText className="title" size="m">
            Shipping
          </EuiText>
        </EuiFlexGroup>
        <EuiText className="details">Arrives in 2 - 4 days</EuiText>
      </EuiFlexGroup>
    </EuiFlexItem>
  );
};
