import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiImage } from "@elastic/eui";
import "./web-top-banner.scss";
import FakeLink from "../../../../components/fake-link/fake-link";
import { useSelector } from "react-redux";
import { locationStateSelector } from "../../../../state/slices/location-slice";
import { TargetStoreIcon } from "../../../../assets/assets";

export const TargetWebTopBanner = () => {
  const location = useSelector(locationStateSelector);

  return (
    <div className="target-web-top-banner">
      <EuiFlexGroup
        className="location"
        direction="row"
        gutterSize="xs"
        style={{ flexGrow: 0, flexShrink: 0 }}
      >
        <EuiFlexGroup
          className="zip"
          direction="row"
          alignItems="center"
          style={{ flexGrow: 0 }}
          gutterSize="s"
        >
          <EuiIcon type="mapMarker" />
          Ship to {location?.postCode}
        </EuiFlexGroup>
        <EuiFlexGroup
          className="street"
          direction="row"
          alignItems="center"
          style={{ flexGrow: 0 }}
          gutterSize="s"
        >
          <EuiImage className="store-icon" src={TargetStoreIcon} alt="" />
          W. Main St.
        </EuiFlexGroup>
      </EuiFlexGroup>

      <EuiFlexGroup
        className="links"
        alignItems="center"
        gutterSize="l"
        justifyContent="flexEnd"
        style={{ flexGrow: 1, flexShrink: 0 }}
      >
        <EuiFlexItem grow={false}>
          <FakeLink text="Target Circle&trade;" />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <FakeLink text="Target Circle&trade; Card" />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <FakeLink text="Target Circle 360&trade;" />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <FakeLink text="Registry & Wish List" />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <FakeLink text="Weekly Ad" />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <FakeLink text="Find Stores" />
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
};
