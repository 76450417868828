import { EuiFlexGroup, EuiFlexItem } from "@elastic/eui";
import "./web-top-banner.scss";
import FakeLink from "../../../../components/fake-link/fake-link";

export const WalgreensWebTopBanner = () => {
  return (
    <div className="walgreens-web-top-banner">
      <EuiFlexGroup
        className="links"
        alignItems="center"
        gutterSize="l"
        justifyContent="spaceAround"
        style={{ flexGrow: 1, flexShrink: 0 }}
      >
        <EuiFlexItem grow={false}>
          <FakeLink text="Extra 15% off $35+ select health & wellness with code HEALTH15" />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <FakeLink text="Shop Weekly Ad" />
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
};
