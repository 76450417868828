import { EuiFlexGroup, EuiFlexItem, EuiIcon } from "@elastic/eui";
import "./web-link-banner.scss";
import FakeLink from "../../../../components/fake-link/fake-link";

export const WalgrensWebLinkBanner = () => {
  return (
    <div className="walgreens-web-link-banner">
      <EuiFlexGroup
        className="links"
        alignItems="center"
        gutterSize="none"
        style={{ flexGrow: 0, flexShrink: 0 }}
      >
        <EuiFlexItem className="walgreens-link" grow={false}>
          <FakeLink text="Prescriptions" />
          <EuiIcon type="arrowDown" />
        </EuiFlexItem>
        <EuiFlexItem className="walgreens-link" grow={false}>
          <FakeLink text="Health Services" />
          <EuiIcon type="arrowDown" />
        </EuiFlexItem>
        <EuiFlexItem className="walgreens-link" grow={false}>
          <FakeLink text="Shop" />
          <EuiIcon type="arrowDown" />
        </EuiFlexItem>
        <EuiFlexItem className="walgreens-link" grow={false}>
          <FakeLink text="Savings" />
          <EuiIcon type="arrowDown" />
        </EuiFlexItem>
        <EuiFlexItem className="walgreens-link" grow={false}>
          <FakeLink text="Photo" />
          <EuiIcon type="arrowDown" />
        </EuiFlexItem>
        <EuiFlexItem className="walgreens-link" grow={false}>
          <FakeLink text="More" />
          <EuiIcon type="arrowDown" />
        </EuiFlexItem>
        <EuiFlexItem className="walgreens-link" grow={false}>
          <FakeLink text="Weekly Ad" />
        </EuiFlexItem>
        <EuiFlexItem className="walgreens-link" grow={false}>
          <FakeLink text="Holiday Shop" />
        </EuiFlexItem>
        <EuiFlexItem className="walgreens-link" grow={false}>
          <FakeLink text="Vaccinations" />
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
};
