import { EuiFlexGroup, EuiIcon, EuiText } from "@elastic/eui";
import "./web-location-banner.scss";
import { WebLocationBannerProps } from "../../../common/web/web-location-banner";

export const WalgreensWebLocationBanner = ({
  city,
  postCode,
}: WebLocationBannerProps) => {
  return (
    <div className="walgreens-web-location-banner">
      <EuiFlexGroup direction="row" alignItems="center" gutterSize="s">
        <EuiIcon type={"mapMarker"} size="l" />
        <EuiText className="location-text"> 2424 N Main St., {city}</EuiText>
      </EuiFlexGroup>
    </div>
  );
};
