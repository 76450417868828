import { EuiFlexGroup, EuiText } from "@elastic/eui";
import FakeLink from "../../../../components/fake-link/fake-link";
import "./web-cart-delivery-info.scss";
import { TargetOrderPickupIcon } from "../../../../assets/assets";

export type ShoppingCartProps = {
  quantity: number;
};

export const TargetWebCartDeliveryInfo = ({
  quantity,
}: ShoppingCartProps): JSX.Element => {
  return (
    <EuiFlexGroup
      className="target-cart-delivery-info"
      direction="column"
      gutterSize="s"
    >
      <EuiFlexGroup direction="column" className="pickup-box" gutterSize="s">
        <EuiFlexGroup
          direction="row"
          alignItems="center"
          justifyContent="flexStart"
          gutterSize="s"
        >
          <img src={TargetOrderPickupIcon} alt="pickup" />
          <EuiText className="delivery-info-title">Order Pickup</EuiText>
        </EuiFlexGroup>
        <EuiFlexGroup direction="row" gutterSize="xs">
          <EuiText>{quantity}</EuiText>
          <EuiText>{quantity === 1 ? "item" : "items"}</EuiText>
          <EuiText>at 2424 N MAIN ST.</EuiText>
        </EuiFlexGroup>
        <EuiText>Ready within 2 hours.</EuiText>
        <FakeLink text="Pickup Details" />
      </EuiFlexGroup>
    </EuiFlexGroup>
  );
};
