import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiImage,
  EuiFieldSearch,
  EuiIcon,
  EuiText,
} from "@elastic/eui";
import { Link } from "react-router-dom";
import "./web-banner.scss";
import {
  TargetAccount,
  TargetCart,
  TargetLogo,
} from "../../../../assets/assets";
import { WebSessionBannerProps } from "../../../common/web/web-session-banner";

export const TargetWebBanner = ({
  shoppingCartQuantity,
}: WebSessionBannerProps) => {
  return (
    <div className="target-web-session-banner">
      <EuiFlexGroup
        className="web-session-search-banner"
        justifyContent="spaceBetween"
        alignItems="center"
        style={{ gap: "10px" }}
      >
        <EuiFlexItem className="logo-wrapper" grow={false}>
          <Link to={"/web"}>
            <EuiImage
              className="target-logo"
              src={TargetLogo}
              alt="Target logo"
            />
          </Link>
        </EuiFlexItem>
        <EuiFlexGroup
          className="buttons"
          direction="row"
          gutterSize="s"
          alignItems="center"
        >
          <EuiFlexGroup className="button" gutterSize="xs" alignItems="center">
            <EuiFlexItem grow={false}>Categories</EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiIcon className="arrow-down" type="arrowDown" size="m" />
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiFlexGroup className="button" gutterSize="xs" alignItems="center">
            <EuiFlexItem grow={false}>Deals</EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiIcon className="arrow-down" type="arrowDown" size="m" />
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiFlexGroup className="button" gutterSize="xs" alignItems="center">
            <EuiFlexItem grow={false}>New & featured</EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiIcon className="arrow-down" type="arrowDown" size="m" />
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiFlexGroup className="button" gutterSize="xs" alignItems="center">
            <EuiFlexItem grow={false}>Pickup & delivery</EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiIcon className="arrow-down" type="arrowDown" size="m" />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexGroup>
        <EuiFlexGroup alignItems="center">
          <EuiFlexItem className="search-box-wrapper" grow={true}>
            <EuiFieldSearch
              className="search-box"
              placeholder="What can we help you find?"
              fullWidth
            />
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiFlexGroup
          className="buttons right-side"
          direction="row"
          gutterSize="s"
          alignItems="center"
        >
          <EuiFlexGroup className="button" gutterSize="xs" alignItems="center">
            <EuiFlexGroup direction="row" gutterSize="s" alignItems="center">
              <EuiImage
                className="account-image"
                src={TargetAccount}
                alt="account"
              />
              <EuiFlexGroup direction="column" gutterSize="none">
                <EuiText className="account-text">Sign In</EuiText>
              </EuiFlexGroup>
              <EuiFlexItem grow={false}>
                <EuiIcon className="arrow-down" type="arrowDown" size="m" />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexGroup>
          <EuiFlexGroup
            className="button shopping-cart-button"
            gutterSize="xs"
            alignItems="center"
          >
            <Link to={"/web/cart"}>
              <EuiFlexGroup
                className="shopping-cart"
                alignItems="center"
                gutterSize="none"
                justifyContent="center"
              >
                <EuiFlexGroup
                  direction="column"
                  justifyContent="spaceAround"
                  alignItems="center"
                  gutterSize="xs"
                >
                  <img
                    className="shopping-cart-image"
                    src={TargetCart}
                    alt="shopping cart logo"
                  />
                </EuiFlexGroup>
                <EuiFlexItem className="shopping-cart-quantity" grow={false}>
                  {shoppingCartQuantity}
                </EuiFlexItem>
              </EuiFlexGroup>
            </Link>
          </EuiFlexGroup>
        </EuiFlexGroup>
      </EuiFlexGroup>
    </div>
  );
};
