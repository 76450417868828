import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiImage,
  EuiFieldSearch,
  EuiIcon,
  EuiText,
} from "@elastic/eui";
import { Link } from "react-router-dom";
import "./web-banner.scss";
import {
  ShoppingCartLogoBlack,
  WalgreensLogo,
} from "../../../../assets/assets";
import { WebSessionBannerProps } from "../../../common/web/web-session-banner";

export const WalgreensWebBanner = ({
  children,
  shoppingCartQuantity,
}: WebSessionBannerProps) => {
  return (
    <div className="walgreens-web-session-banner">
      <EuiFlexGroup
        className="web-session-search-banner"
        justifyContent="spaceBetween"
        alignItems="center"
      >
        <EuiFlexGroup alignItems="center">
          <EuiFlexItem grow={false}>
            <Link to={"/web"}>
              <EuiImage
                className="walgreens-logo"
                src={WalgreensLogo}
                alt="Walgreens logo"
              />
            </Link>
          </EuiFlexItem>
          <EuiFlexItem className="search-box-wrapper" grow={true}>
            <EuiFieldSearch
              className="search-box"
              placeholder="Search"
              fullWidth
            />
          </EuiFlexItem>
          {children}
          {/*  this will be the location bar  */}
        </EuiFlexGroup>
        <EuiFlexItem grow={false} className="right-side">
          <EuiFlexGroup
            gutterSize="l"
            justifyContent="spaceAround"
            alignItems="center"
          >
            <EuiFlexGroup direction="row" gutterSize="s" alignItems="center">
              <EuiIcon type="user" size="l" />
              <EuiFlexGroup direction="column" gutterSize="none">
                <EuiText className="account-text">Account</EuiText>
              </EuiFlexGroup>
            </EuiFlexGroup>

            <Link to={"/web/cart"}>
              <EuiFlexGroup
                className="shopping-cart"
                alignItems="center"
                gutterSize="none"
                justifyContent="center"
              >
                <EuiFlexGroup
                  direction="column"
                  justifyContent="spaceAround"
                  alignItems="center"
                  gutterSize="xs"
                >
                  <img
                    className="shopping-cart-image"
                    src={ShoppingCartLogoBlack}
                    alt="shopping cart logo"
                  />
                </EuiFlexGroup>
                <EuiFlexItem className="shopping-cart-quantity" grow={false}>
                  {shoppingCartQuantity}
                </EuiFlexItem>
              </EuiFlexGroup>
            </Link>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
};
