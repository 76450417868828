import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiIcon,
  EuiImage,
  EuiSpacer,
  EuiText,
} from "@elastic/eui";
import "./web-item-detail-pricing.scss";
import { WebItemProps } from "../../../common/web/web-item-props";
import {
  TargetOrderDeliveryIcon,
  TargetOrderPickupIcon,
  TargetOrderShippingIcon,
} from "../../../../assets/assets";
import { toCurrency } from "../../../../utils/text-utils";
import { useMemo } from "react";

export const TargetWebItemDetailPricing = ({ item }: WebItemProps) => {
  const days = useMemo(
    () => ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    []
  );

  const months = useMemo(
    () => [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    []
  );

  const arrivalDate = useMemo(() => {
    const date = new Date();
    date.setDate(date.getDate() + 4);
    return `${days[date.getDay()]}, ${
      months[date.getMonth()]
    } ${date.getDate()}`;
  }, [days, months]);

  return (
    <EuiFlexGroup
      className="target-item-details--price"
      direction="column"
      justifyContent="flexStart"
      gutterSize="s"
    >
      <EuiFlexGroup
        className="walmart-item-details--price"
        direction="row"
        justifyContent="flexStart"
        gutterSize="none"
        alignItems="center"
      >
        <EuiIcon style={{ color: "#ffc220" }} type={"starFilledSpace"} />
        <EuiIcon style={{ color: "#ffc220" }} type={"starFilledSpace"} />
        <EuiIcon style={{ color: "#ffc220" }} type={"starFilledSpace"} />
        <EuiIcon style={{ color: "#ffc220" }} type={"starFilledSpace"} />
        <EuiIcon style={{ color: "#ffc220" }} type={"starFilledSpace"} />
        <EuiText size="s" style={{ textDecoration: "underline" }}>
          12332
        </EuiText>
        <EuiText size="m" style={{ marginLeft: "10px", marginRight: "10px" }}>
          |
        </EuiText>
        <EuiText size="s" style={{ textDecoration: "underline" }}>
          48 Questions
        </EuiText>
      </EuiFlexGroup>
      <EuiText className="item-details--price-values">
        {toCurrency(item.price)}
      </EuiText>
      <EuiHorizontalRule margin="m" />
      <EuiFlexGroup
        className="item-details--price-box"
        justifyContent="flexStart"
        gutterSize="s"
      >
        <EuiFlexGroup
          gutterSize="xs"
          alignItems="flexStart"
          direction="column"
          className="checkout-option  selected"
        >
          <EuiImage
            className="item-details--pickup-icon"
            src={TargetOrderPickupIcon}
            alt="Pickup logo"
          />
          <EuiText className="item-details--price-pickup">Pickup</EuiText>
          {/* <EuiIcon type="arrowDown" size="s" color="primary" /> */}
          <EuiText className="item-details--price-availability">
            Ready within 3 hours
          </EuiText>
        </EuiFlexGroup>
        <EuiFlexGroup
          gutterSize="xs"
          alignItems="flexStart"
          direction="column"
          className="checkout-option"
        >
          <EuiImage
            className="item-details--pickup-icon"
            src={TargetOrderDeliveryIcon}
            alt="Delivery logo"
          />
          <EuiText className="item-details--price-pickup">Delivery</EuiText>
          <EuiText className="item-details--price-availability">
            Select delivery window at checkout
          </EuiText>
        </EuiFlexGroup>
        <EuiFlexGroup
          gutterSize="xs"
          alignItems="flexStart"
          direction="column"
          className="checkout-option"
        >
          <EuiImage
            className="item-details--pickup-icon"
            src={TargetOrderShippingIcon}
            alt="shipping logo"
          />
          <EuiText className="item-details--price-pickup">Shipping</EuiText>
          <EuiText className="item-details--price-availability">
            Arrives by {arrivalDate}
          </EuiText>
        </EuiFlexGroup>
      </EuiFlexGroup>
      <EuiSpacer size="m" />
      <EuiFlexGroup gutterSize="s" style={{ flexGrow: 0 }}>
        <EuiText className="item-details--in-stock">Pick up at </EuiText>
        <EuiText className="item-details--address">2424 N MAIN ST.</EuiText>
        <EuiText className="item-details--check-more-stores">
          Check other stores
        </EuiText>
      </EuiFlexGroup>
      <EuiFlexGroup gutterSize="s" style={{ flexGrow: 0 }}>
        <EuiText
          className="item-details--in-stock"
          style={{ color: "#008300", fontWeight: "400" }}
        >
          Ready within 2 hours
        </EuiText>
        <EuiText
          className="item-details--address"
          style={{ textDecoration: "none", fontWeight: "400" }}
        >
          for pickup inside the store
        </EuiText>
      </EuiFlexGroup>
      <EuiFlexItem grow={false}></EuiFlexItem>
    </EuiFlexGroup>
  );
};
