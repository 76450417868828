import { EuiFlexGroup, EuiText } from "@elastic/eui";
import FakeLink from "../../../../components/fake-link/fake-link";
import "./web-cart-delivery-info.scss";
import { WalgreensPickupIconRed } from "../../../../assets/assets";
import { useSelector } from "react-redux";
import { locationStateSelector } from "../../../../state/slices/location-slice";

export const WalgreensWebCartDeliveryInfo = () => {
  const location = useSelector(locationStateSelector);

  return (
    <EuiFlexGroup
      className="walgreens-cart-delivery-info"
      direction="column"
      gutterSize="s"
    >
      <EuiFlexGroup
        direction="row"
        alignItems="center"
        justifyContent="flexStart"
        gutterSize="s"
      >
        <img src={WalgreensPickupIconRed} alt="pickup" />
        <EuiText className="delivery-info-title">Pickup</EuiText>
      </EuiFlexGroup>

      <EuiFlexGroup direction="column" className="pickup-box" gutterSize="s">
        <EuiFlexGroup direction="row" gutterSize="s">
          <EuiText style={{ fontWeight: "700", fontSize: "16px" }}>
            Your pickup store:
          </EuiText>
          <EuiText>
            2424 N MAIN ST., {location?.city}, {location?.stateCode}
          </EuiText>
        </EuiFlexGroup>
        <EuiText>
          Ready in as little as 30 minutes, if order placed at least 1 hour
          before store closes.
        </EuiText>
        <FakeLink text="Pickup Details" />
      </EuiFlexGroup>
    </EuiFlexGroup>
  );
};
