import { WalgreensLogo } from "../../assets/assets";
import { AbstractCompanyComponentResolver } from "./abstract-company-resolver";
import { Location } from "../../services/geoapify-service";
import { ItemMaster } from "../../model/pos/item-master";
import { WebHomePageProps } from "../common/web/web-home-page";
import { WebItemDetailOrderingProps } from "../common/web/web-item-detail-ordering";
import { WebItemProps } from "../common/web/web-item-props";
import { WalgreensWebTopBanner } from "../walgreens/web/web-top-banner/web-top-banner";
import { WalgreensWebBanner } from "../walgreens/web/web-banner/web-banner";
import { WalgreensWebLocationBanner } from "../walgreens/web/web-location-banner/web-location-banner";
import { WalgrensWebLinkBanner } from "../walgreens/web/web-link-banner/web-link-banner";
import WalgreensWebsiteHomePage from "../walgreens/web/web-home-page/web-home-page";
import { WebItemPath } from "../common/web/web-item-path.ts/web-item-path";
import { WalgreensWebItemDetailPricing } from "../walgreens/web/web-item-detail-pricing/web-item-detail-pricing";
import { WalgreensWebItemDetailOrdering } from "../walgreens/web/web-item-detail-ordering/web-item-detail-ordering";
import { WalgreensWebItemFurtherDetails } from "../walgreens/web/web-item-detail-further-details/web-item-detail-further-details";
import { WalgreensWebCartDeliveryInfo } from "../walgreens/web/web-cart-delivery-info/web-cart-delivery-info";

export class WalgreensComponentResolver extends AbstractCompanyComponentResolver {
  constructor() {
    super("walgreens");
  }

  getItemFilters(): string {
    return " and demoCategories like '%walgreens%' or demoCategories like '%grocery%'";
  }

  getLogo() {
    return WalgreensLogo;
  }

  getLogoLight() {
    return WalgreensLogo;
  }

  getWebHeader(shoppingCartQuantity: number, location?: Location): JSX.Element {
    return (
      <>
        <WalgreensWebTopBanner />
        <WalgreensWebBanner shoppingCartQuantity={shoppingCartQuantity}>
          <WalgreensWebLocationBanner
            city={location?.city ?? ""}
            state={location?.state ?? ""}
            postCode={location?.postCode ?? ""}
          />
        </WalgreensWebBanner>
        <WalgrensWebLinkBanner />
      </>
    );
  }

  getHomePage(props: WebHomePageProps): JSX.Element {
    return <WalgreensWebsiteHomePage {...props} />;
  }

  getCartDeliveryInfo(): JSX.Element {
    return <WalgreensWebCartDeliveryInfo />;
  }

  getShippingText(): string {
    return "Pickup for";
  }

  getWebItemPath(item: ItemMaster): JSX.Element {
    return <WebItemPath item={item} separator={"arrow-right"} />;
  }

  getItemDetailPricing(props: WebItemProps): JSX.Element {
    return <WalgreensWebItemDetailPricing {...props} />;
  }

  getItemDetailOrdering(props: WebItemDetailOrderingProps): JSX.Element {
    return <WalgreensWebItemDetailOrdering {...props} />;
  }

  getItemDetailFurtherDetails(props: WebItemProps): JSX.Element {
    return <WalgreensWebItemFurtherDetails {...props} />;
  }

  getItemDetailFeatures(props: WebItemProps): JSX.Element {
    return <></>;
  }
}
