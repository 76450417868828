import CostcoLogo from "./costco-logo.svg";
import ShoppingCartLogoBlue from "./cart-blue.svg";
import ShoppingCartLogoWhite from "./cart-white.svg";
import ShoppingCartLogoBlack from "./cart-black.svg";
import BarCode from "./bar-code.svg";
import HomePageShoppingBanner1 from "./costco-homepage/top.avif";
import HomePageShoppingBanner2 from "./kroger-homepage/pizza.webp";
import HomePageShoppingBannerCVS from "./cvs-homepage/CVS_header.png";
import StopSign from "./stopsign.jpg";
import KrogerLogo from "./kroger-logo-white.svg";
import KrogerLogoBlue from "./kroger-logo-blue.svg";
import PickupLogo from "./pickup.svg";
import CvsLogo from "./cvs-homepage/cvs-logo.svg";
import CvsPickupIcon from "./cvs-homepage/cvs_pickup.png";
import WalmartLogo from "./walmart-homepage/Walmart_Spark.svg";
import WalmartMobileIcon from "./walmart-homepage/pickup_icon.webp";
import WalmartBoxesIcon from "./walmart-homepage/boxes-icon.png";
import WalmartCirclesIcon from "./walmart-homepage/circles-icon.png";
import WalmartHomePageBanner from "./walmart-homepage/home-banner-images.png";
import WalmartShippingIcon from "./walmart-homepage/shipping-icon.png";
import WalmartPickupIcon from "./walmart-homepage/pickup-icon.png";
import SamsclubLogo from "./samsclub-homepage/samsclub-logo.svg";
import SamsclubBlueLogo from "./samsclub-homepage/samsclub-logo-blue.svg";
import SamsReorderIcon from "./samsclub-homepage/nav-reorder.svg";
import SamsHeader from "./samsclub-homepage/sams-club-header.jpeg";
import SamsclubShippingIcon from "./samsclub-homepage/shipping.svg";
import SamsclubPickupIcon from "./samsclub-homepage/pickup.svg";
import SamsclubDeliveryIcon from "./samsclub-homepage/delivery.svg";
import SamsclubPlusIcon from "./samsclub-homepage/plus.svg";
import WalgreensLogo from "./walgreens-homepage/logo.png";
import WalgreensHeader from "./walgreens-homepage/walgreens-banner.png";
import WalgreensShippingIcon from "./walgreens-homepage/shipping.svg";
import WalgreensDeliveryIcon from "./walgreens-homepage/delivery.svg";
import WalgreensPickupIcon from "./walgreens-homepage/same-day-pickup.svg";
import WalgreensPickupIconRed from "./walgreens-homepage/same-day-pickup-red.svg";
import TargetStoreIcon from "./target-homepage/store-icon.svg";
import TargetLogo from "./target-homepage/target-logo.svg";
import TargetAccount from "./target-homepage/account.svg";
import TargetCart from "./target-homepage/cart.svg";
import TargetHomepageHeader from "./target-homepage/target-header.png";
import TargetHomepageHeader0 from "./target-homepage/target-header-0.png";
import TargetOrderDeliveryIcon from "./target-homepage/order-delivery.svg";
import TargetOrderPickupIcon from "./target-homepage/order-pickup.svg";
import TargetOrderShippingIcon from "./target-homepage/order-shipping.svg";

export {
  /** Costco */
  CostcoLogo,
  HomePageShoppingBanner1,
  /** Kroger */
  HomePageShoppingBanner2,
  ShoppingCartLogoBlue,
  ShoppingCartLogoWhite,
  KrogerLogo,
  KrogerLogoBlue,
  PickupLogo,
  /** Cvs */
  CvsLogo,
  HomePageShoppingBannerCVS,
  CvsPickupIcon,
  /** Sams Club */
  SamsclubLogo,
  SamsclubBlueLogo,
  SamsReorderIcon,
  SamsHeader,
  SamsclubShippingIcon,
  SamsclubPickupIcon,
  SamsclubDeliveryIcon,
  SamsclubPlusIcon,
  /** Walgreens */
  WalgreensLogo,
  WalgreensHeader,
  WalgreensPickupIcon,
  WalgreensPickupIconRed,
  WalgreensDeliveryIcon,
  WalgreensShippingIcon,
  /** Walmart */
  WalmartLogo,
  WalmartMobileIcon,
  WalmartBoxesIcon,
  WalmartCirclesIcon,
  WalmartHomePageBanner,
  WalmartShippingIcon,
  WalmartPickupIcon,
  /** Target */
  TargetStoreIcon,
  TargetLogo,
  TargetAccount,
  TargetCart,
  TargetHomepageHeader,
  TargetHomepageHeader0,
  TargetOrderDeliveryIcon,
  TargetOrderPickupIcon,
  TargetOrderShippingIcon,
  /** Other */
  BarCode,
  ShoppingCartLogoBlack,
  StopSign,
};
