import { EuiFlexGroup, EuiIcon, EuiSpacer, EuiText } from "@elastic/eui";
import "./web-item-detail-pricing.scss";
import { WebItemProps } from "../../../common/web/web-item-props";

export const WalgreensWebItemDetailPricing = ({ item }: WebItemProps) => {
  return (
    <EuiFlexGroup
      className="walgreens-item-details--price"
      direction="column"
      justifyContent="flexStart"
      gutterSize="none"
      alignItems="baseline"
    >
      <EuiFlexGroup
        direction="row"
        gutterSize="none"
        justifyContent="flexStart"
        alignItems="center"
      >
        <EuiText size="s" style={{ marginRight: "5px" }}>
          4.8
        </EuiText>
        <EuiIcon style={{ color: "#000" }} type={"starFilledSpace"} />
        <EuiIcon style={{ color: "#000" }} type={"starFilledSpace"} />
        <EuiIcon style={{ color: "#000" }} type={"starFilledSpace"} />
        <EuiIcon style={{ color: "#000" }} type={"starFilledSpace"} />
        <EuiIcon style={{ color: "#000" }} type={"starFilledSpace"} />
        <EuiText size="s">120053</EuiText>
      </EuiFlexGroup>
      <EuiSpacer size="s" />
      <EuiText className="price">${item?.price}</EuiText>
      <EuiSpacer size="s" />
      <EuiText className="">When ordered online</EuiText>
    </EuiFlexGroup>
  );
};
