import { EuiFlexItem, EuiFlexGroup, EuiText, EuiSpacer } from "@elastic/eui";
import "./web-item-detail-further-details.scss";
import { WebItemProps } from "../../../common/web/web-item-props";

export const TargetWebItemFurtherDetails = ({ item }: WebItemProps) => {
  return (
    <EuiFlexGroup
      key={"item-further-details"}
      className="target-item-further-details"
      direction="column"
      alignItems="center"
    >
      <EuiFlexItem className="product-details" grow={false}>
        <EuiFlexGroup
          direction="column"
          gutterSize="none"
          style={{ borderTop: "1px solid #413d3c" }}
        >
          <EuiSpacer size="m" />
          <EuiText className="section-name-title">Highlights</EuiText>
          <EuiSpacer size="m" />
          <EuiFlexItem>
            <ul>
              {item.primaryFeatures?.map((feature) => {
                return <li key={feature}>{feature}</li>;
              })}
            </ul>
          </EuiFlexItem>
          <EuiSpacer size="m" />
        </EuiFlexGroup>
        <EuiFlexGroup
          direction="column"
          gutterSize="none"
          style={{ borderTop: "1px solid #413d3c" }}
        >
          <EuiSpacer size="l" />
          <EuiText className="section-name-title">Specifications</EuiText>
          <EuiSpacer size="m" />
          <EuiFlexItem>
            <table>
              <tbody>
                {item.specifications?.map((spec) => {
                  return (
                    <tr key={spec[1]}>
                      <td>{spec[0]}</td>
                      <td>{spec[1]}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </EuiFlexItem>
          <EuiSpacer size="s" />
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
