import {
  EuiFlexItem,
  EuiFlexGroup,
  EuiIcon,
  EuiButton,
  EuiSpacer,
  EuiText,
} from "@elastic/eui";
import "./web-item-detail-ordering.scss";
import { WebItemDetailOrderingProps } from "../../../common/web/web-item-detail-ordering";

export const TargetWebItemDetailOrdering = ({
  addToCart,
}: WebItemDetailOrderingProps) => {
  return (
    <>
      <EuiSpacer size="s" />

      <EuiFlexItem className="target-item-ordering-wrapper" grow={false}>
        <EuiFlexGroup
          className="item-ordering"
          direction="row"
          style={{ flexGrow: 0 }}
          gutterSize="m"
        >
          <EuiFlexGroup className="quantity" gutterSize="xs">
            <EuiText>Qty</EuiText>
            <EuiText>1</EuiText>
            <EuiIcon type="arrowDown" size="s" />
          </EuiFlexGroup>
          <EuiButton onClick={addToCart}>Add to cart</EuiButton>
        </EuiFlexGroup>
      </EuiFlexItem>
    </>
  );
};
